import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App } from "App";
import Landing from "pages/Marketplace/Landing";
import ListingsPage from "pages/Marketplace/Marketplace";
import Listing from "pages/ListingView/ListingView";
import CreateListing from "pages/CreateListing/CreateListing";
import { LoginForm, SignupForm } from "components/Userfront";
import AccountSecurity from "pages/Account/AccountSecurity/AccountSecurity";
import MakerListings from "pages/MakerDashboard/MakerListings/MakerListings";
import UserProfile from "pages/UserProfile/UserProfile";
import StripeReturn from "functions/StripeReturn";
import TakerReservations from "pages/TakerDashboard/TakerReservations/TakerReservations";
import Calendar from "pages/Calendar/Calendar";
import Admin from "pages/AdminDashboard/AdminDashboard";
import Reset from "pages/AdminDashboard/passwordReset";
import Chats from "pages/Messages/Chats";
import Account from "pages/Account/Account";
import UserContext from "context/UserContext";
import ViewTransitionWrapper from "pages/ViewTransitionWrapper";
import EmailTest from "pages/EmailTest";

// const PrivateRoute = ({ element, path, isAllowed, redirectTo }) => {
//   return (
//     <Route
//       path={path}
//       element={isAllowed ? element : <Navigate to={redirectTo} replace />}
//     />
//   );
// };

function Router() {
  const { user } = useContext(UserContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/logged-out" element={<Landing />} />
          <Route
            path="/"
            element={
              <ViewTransitionWrapper>
                {user?.data?.state === "Maker" ? <Account /> : <ListingsPage />}
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/user/:id"
            element={
              <ViewTransitionWrapper>
                <UserProfile />
              </ViewTransitionWrapper>
            }
          />

          <Route
            path="/listings/:id"
            element={
              <ViewTransitionWrapper>
                {" "}
                <Listing />{" "}
              </ViewTransitionWrapper>
            }
          />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="/dashboard"
            element={
              <ViewTransitionWrapper>
                {" "}
                <Account />{" "}
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/account-security"
            element={
              <ViewTransitionWrapper>
                {" "}
                <AccountSecurity />{" "}
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/calendar"
            element={
              <ViewTransitionWrapper>
                {" "}
                <Calendar />{" "}
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/your-reservations"
            element={
              <ViewTransitionWrapper>
                {" "}
                <TakerReservations />
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/return-from-stripe"
            element={
              <ViewTransitionWrapper>
                {" "}
                <StripeReturn />
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/reauth-from-stripe"
            element={
              <ViewTransitionWrapper>
                {" "}
                <StripeReturn reauth />
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/create-listing"
            element={
              <ViewTransitionWrapper>
                {" "}
                <CreateListing />
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="/your-listings"
            element={
              <ViewTransitionWrapper>
                {" "}
                <MakerListings />
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="messages"
            element={
              <ViewTransitionWrapper>
                {" "}
                <Chats />
              </ViewTransitionWrapper>
            }
          />
          <Route
            path="email"
            element={
              <ViewTransitionWrapper>
                {" "}
                <EmailTest />
              </ViewTransitionWrapper>
            }
          />
          <Route path="/reset" element={<Reset />} />
          <Route
            path="/admin-dashboard"
            element={
              user?.data?.adminRole === "Admin" ? <Admin /> : <Account />
            }
          />
          <Route
            path="/maker-dashboard/create-listing"
            element={<CreateListing />}
          />
          <Route
            path="/maker-dashboard/your-listings"
            element={<MakerListings />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
