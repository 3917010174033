import axios from "axios";
import Userfront from "@userfront/react";
import Swal from "sweetalert2";
import NewMessage from "Business/Email/NewMessage";
import { render } from "@react-email/render";
import MakerReserve from "Business/Email/MakerReserve";

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const USERS_URL = process.env.REACT_APP_USERS_API_ENDPOINT;
const BEARER_TOKEN = process.env.REACT_APP_USERFRONT_BEARER;

// universal error function
// just add your message to it

export function errorMessageAlert(message) {
  Swal.fire({
    icon: "error",
    title: "Sorry...",
    text: `We ${message}, please try again`,
    customClass: {
      confirmButton: "button-primary big-btn wide",
    },
    buttonsStyling: false,
  });
}

// Userfront functions

// Gets userfront data for a user
export async function getUser(userUuid) {
  try {
    const response = await axios.get(`${USERS_URL}users/${userUuid}`, {
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${BEARER_TOKEN}`,
      },
    });
    const response2 = await axios({
      method: "get",
      url: `${API_URL}user/${Userfront.user.userUuid}`,
    });
    const user = response2.data;

    if (!user.is_active) {
      // eslint-disable-next-line no-alert
      alert("Your account has been deactivated. You will be logged out.");
      Userfront.logout();
      return null;
    }

    return response.data;
  } catch (error) {
    throw Error("failed to load user");
  }
}

export async function updateUserStatus(userUuid, isActive, userData) {
  try {
    const selectedUser = userData.find((user) => user.uuid === userUuid);
    if (!selectedUser) {
      // eslint-disable-next-line no-console
      console.error("User not found");
      return Promise.reject(new Error("Some error message"));
    }

    await axios.put(`${API_URL}user/${userUuid}`, {
      uuid: userUuid,
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      email: selectedUser.email,
      is_active: isActive,
      is_junior_admin: selectedUser.is_junior_admin,
      is_senior_admin: selectedUser.is_senior_admin,
      is_organizational_admin: selectedUser.is_organizational_admin,
      is_admin: selectedUser.is_admin,
      is_maker: selectedUser.is_maker,
    });

    return Promise.resolve();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error updating user status: ${error}`);
    return Promise.reject(error);
  }
}

// Updates a users image in usefront
// Used if a default image is chosen, since theres no need to upload to s3
export async function updateUserImage(url) {
  const payload = {
    image: url,
  };
  try {
    const response = await axios.put(
      `${USERS_URL}users/${Userfront.user.userUuid}`,
      payload,
      {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return new Error();
  }
}

export async function updateUserState(data) {
  const payload = {
    data,
  };
  try {
    const response = await axios.put(
      `${USERS_URL}users/${Userfront.user.userUuid}`,
      payload,
      {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return new Error();
  }
}

// API functions

// Gets API data forall users
export async function getAllUsers() {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}user/`,
    });
    // console.log("Response data:", response.data);

    return response.data;
  } catch (error) {
    throw Error("failed to load users");
  }
}

// Loads reservations and stores in local storage
export async function loadReservations() {
  if (Userfront.user.userUuid !== undefined) {
    try {
      const response = await axios({
        method: "get",
        url: `${API_URL}reservations/?taker=${Userfront.user.userUuid}`,
      });
      localStorage.setItem(
        "userReservationList",
        JSON.stringify(response.data)
      );
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
}
export async function paidReserve(alisting, reservation) {
  const emailObj = {
    maker: {
      first_name: "Maker",
    },
    listing: {
      name: alisting.name,
      listing_id: alisting.listing_id,
    },
    taker: {
      name: Userfront.user.name,
      image: Userfront.user.image,
    },
  };
  const html = render(<MakerReserve obj={emailObj} />, {
    pretty: true,
  });

  const response = await axios({
    method: "post",
    url: `${API_URL}create-checkout-session/${alisting.listing_id}/?as=${Userfront.user.userUuid}`,
    data: {
      reservation,
      html,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const body = response.data;
  return body.url;
}

export async function freeReserve(alisting, reservation) {
  const emailObj = {
    maker: {
      first_name: "Maker",
    },
    listing: {
      name: alisting.name,
      listing_id: alisting.listing_id,
    },
    taker: {
      name: Userfront.user.name,
      image: Userfront.user.image,
    },
  };
  const html = render(<MakerReserve obj={emailObj} />, {
    pretty: true,
  });
  try {
    const response = await axios({
      method: "post",
      url: `${API_URL}reservations/`,
      data: {
        reservation,
        html,
      },
    });
    return response.data;
  } catch (error) {
    return new Error();
  }
}

// Deletes a reservation
export async function deleteReservation(resId) {
  try {
    await axios({
      method: "delete",
      url: `${API_URL}reservations/${resId}`,
    });
  } catch (error) {
    throw Error("failed to delete");
  }
}

// Confirms a reservation has been picked up
export async function completeReservation(resId) {
  try {
    await axios({
      method: "patch",
      url: `${API_URL}reservations/${resId}`,
      data: {
        is_completed: true,
      },
    });
  } catch (error) {
    throw Error("failed to delete");
  }
}

// Patches a listing to mark it as rated
export async function changePickupTime(listingId, startDate, endDate) {
  try {
    await axios({
      method: "patch",
      url: `${API_URL}listings/changePickupTimes/${listingId}/${startDate}/${endDate}/`,
    });
  } catch (error) {
    throw Error("failed to delete");
  }
}

// Patches a listing to mark it as rated
export async function markListingRated(listingId) {
  try {
    await axios({
      method: "patch",
      url: `${API_URL}listings/${listingId}`,
      data: {
        is_rated: true,
      },
    });
  } catch (error) {
    throw Error("failed to delete");
  }
}

// Maker posts rating to TakerRating table
export async function makerSubmitRating(rating, reservation) {
  const data = {
    rating_value: rating.rating,
    description: rating.description,
    rater: Userfront.user.userUuid,
    rated: reservation.taker.uuid,
    reservation_id: reservation.reservation_id,
  };
  try {
    await axios({
      method: "post",
      url: "http://127.0.0.1:8000/taker-reviews/",
      data,
    });
  } catch (error) {
    throw Error("failed to post review");
  }
}

// Taker posts rating to MakerRating table
export async function takerSubmitRating(rating, rated, reservation) {
  const data = {
    rating_value: rating.rating,
    description: rating.description,
    rated,
    rater: Userfront.user.userUuid,
    reservation_id: reservation.reservation_id,
  };
  try {
    await axios({
      method: "post",
      url: `${API_URL}maker-reviews/`,
      data,
    });
  } catch (error) {
    throw Error("failed to post review");
  }
}

// Taker posts rating to MakerRating table
export async function loadMakerReviews(uuid) {
  try {
    const reviews = await axios({
      method: "get",
      url: `${API_URL}maker-reviews/?uuid=${uuid}`,
    });
    return reviews;
  } catch (error) {
    throw Error("failed to post review");
  }
}

// Taker posts rating to MakerRating table
export async function loadTakerReviews(uuid) {
  try {
    const reviews = await axios({
      method: "get",
      url: `${API_URL}taker-reviews/?uuid=${uuid}`,
    });
    return reviews;
  } catch (error) {
    throw Error("failed to post review");
  }
}

// Gets a single listing
export async function getListing(id) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}listings/${id}`,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    return {};
  }
}

// Deletes a single listing
export async function deleteListing(id) {
  try {
    const response = await axios({
      method: "delete",
      url: `${API_URL}listings/${id}`,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    return error;
  }
}

// Gets all listings
export async function getListings() {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}listings/`,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    return error;
  }
}

// Gets listings with search criteria
export async function getAlteredListings(criteria) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}listings/?${criteria}`,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    return error;
  }
}

// Gets all listings tied to a maker
export async function loadMakerListings(userUuid) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}listings/${userUuid}`,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    return {};
  }
}

export async function loadListingsByMonth(month, year, cancelToken) {
  const response = await axios({
    method: "get",
    url: `${API_URL}listings/${Userfront.user.userUuid}/${year}/${month}`,
    cancelToken,
  });
  return response.data;
}

export async function loadReservationsByMonth(month, year, cancelToken) {
  const response = await axios({
    method: "get",
    url: `${API_URL}reservations/${Userfront.user.userUuid}/${year}/${month}`,
    cancelToken,
  });
  return response.data;
}

// Updates User Profile Picture by uploading to s3
export async function uploadUserPicture(imageObj) {
  const userUuid = Userfront.user.userUuid;
  const oldUrl = Userfront.user.image;
  const userId = Userfront.user.userId;

  const formData = new FormData();
  formData.append("oldUrl", oldUrl);
  formData.append("userId", userId);
  formData.append("newImage", imageObj.image);

  try {
    const response = await axios.patch(`${API_URL}user/${userUuid}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

// Gets Stripe onboarding link, become a Maker
export async function getOnboardingLink() {
  const oldData = Userfront.user.data;
  const userId = Userfront.user.userId;

  const body = {
    user_id: userId,
    data: oldData,
  };

  try {
    const response = await axios.post(`${API_URL}new-maker/`, body, {});
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function verifyCaptcha(token) {
  try {
    const response = await axios.post(`${API_URL}verify-captcha/`, {
      token,
    });
    return response.data.success;
  } catch (error) {
    return error;
  }
}

export async function getMakerChats(makerId) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}chats/maker`,
      params: {
        maker: makerId,
      },
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error fetching chat:", error);
    throw error;
  }
}

export async function getTakerChats(takerId) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}chats/taker`,
      params: {
        taker: takerId,
      },
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error fetching chat:", error);
    throw error;
  }
}

export async function getListingChats(listingId) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}chats/listing`,
      params: {
        listing: listingId,
      },
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error fetching chat:", error);
    throw error;
  }
}

export async function getChat(takerId, makerId, listingId, cancelToken) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}chats/`,
      params: {
        taker: takerId,
        maker: makerId,
        listing: listingId,
      },
      cancelToken,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error fetching chat:", error);
    throw error;
  }
}

export async function getMessages(chatId) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URL}chats/${chatId}/`,
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error fetching messages:", error);
    throw error;
  }
}

export async function sendMessageEmail(data) {
  const html = render(<NewMessage obj={data} />, {
    pretty: true,
  });

  try {
    const response = await axios({
      method: "post",
      url: `${API_URL}email/new-message/`,
      data: {
        email: data.recipient_email,
        html,
      },
    });
    return response;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error sending message:", error);
    throw error;
  }
}

export async function sendMessage(chatId, messageData) {
  try {
    const response = await axios({
      method: "post",
      url: `${API_URL}messages/${chatId}/`,
      data: {
        sender_uuid: Userfront.user.userUuid,
        content: messageData,
      },
    });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error sending message:", error);
    throw error;
  }
}

// Gets a single user using their unique id
export async function getSingleUser(userUuid) {
  // eslint-disable-next-line
  console.log(userUuid);
  try {
    const response = await axios.get(`${API_URL}users/${userUuid}`);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Failed to load user", error);
    throw error;
  }
}

export async function updateAdminRole(
  userUuid,
  firstName,
  lastName,
  email,
  isJuniorAdmin,
  isSeniorAdmin,
  isOrganizationAdmin,
  isAdmin,
  isMaker
) {
  // eslint-disable-next-line
  console.log(`Updating role for userUuid: ${userUuid}`);
  try {
    await axios.put(`${API_URL}user/${userUuid}`, {
      uuid: userUuid,
      first_name: firstName,
      last_name: lastName,
      email,
      is_junior_admin: isJuniorAdmin,
      is_senior_admin: isSeniorAdmin,
      is_organizational_admin: isOrganizationAdmin,
      is_admin: isAdmin,
      is_maker: isMaker,
    });
    // eslint-disable-next-line
    console.log(`
    Roles updated: JA: ${isJuniorAdmin}, SA: ${isSeniorAdmin}, OA: ${isOrganizationAdmin}, Maker: ${isMaker}`);
  } catch (error) {
    // eslint-disable-next-line
    console.error(`Error updating roles for userUuid ${userUuid}:`, error);
    throw error;
  }
}

// Swal functions

// Prompt for delete reservation for a taker
// Returns the promise that SweetAlerts generates
// The caller of this function is wrapped in async / await
export function cancelReservationAlert(reservation) {
  return Swal.fire({
    title: `Cancel reservation for \n${reservation.listing_id.name}?`,
    text: "You will lose access to this material",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Confirm",
    buttonsStyling: false,
  });
}

export function successfulReserveAlert() {
  return Swal.fire({
    title: `Reservation Successful!`,
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
    },
    confirmButtonText: "Okay!",
    buttonsStyling: false,
  });
}

export function failedToLoadImageAlert() {
  return Swal.fire({
    text: "We were unable to load your image. Ther rest of your listing has been autofilled.",
    icon: "warning",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

// Success message for profile picture change
export function afterUserPictureChange() {
  return Swal.fire({
    title: `Success`,
    text: "Your profile picture was changed",
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}
// Fires after successful creating a listing
export function reservationSuccessAlert() {
  return Swal.fire({
    title: `Reservation Successful`,
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Hooray!",
    buttonsStyling: false,
  });
}

// Prompt for delete reservation for a maker
export function cancelResAlertMaker(reservation) {
  return Swal.fire({
    title: `Cancel ${reservation.taker.first_name}'s reservation?`,
    text: "They will lose access to this material, and your listing will be made active",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Confirm",
    buttonsStyling: false,
  });
}

// Prompt to confirm the pickup of a reservation for a taker
// Returns the promise that SweetAlerts generates
// The caller of this function is wrapped in async / await
export function confirmPickupAlert(name) {
  return Swal.fire({
    title: `Confirm your pickup of \n${name}?`,
    text: "Your reservation will be marked as completed. This action cannot be undone",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Confirm",
    buttonsStyling: false,
  });
}

// Prompt for user log out
export function logoutAlert() {
  Swal.fire({
    title: `Log out?`,
    text: "You will be returned to the home screen",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Confirm",
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) Userfront.logout();
  });
}

// Prompt to become a maker - needs stripe
export function becomeMakerAlert(thenFunction) {
  Swal.fire({
    title: "Start selling on Jackrabbit",
    html: "<div style='font-size: 1rem;'>In order become a maker and begin selling on Jackrabbit you must create a connected stripe account.<br><br>Click continue to begin the stripe onboarding process.<br> Your profile's public url will be copied to your clipboard, you will need this for setup.</div>",
    icon: "info",
    showCancelButton: true,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Continue",
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) thenFunction();
  });
}

// Returns the promise that SweetAlerts generates
export function deleteListingAlert(name) {
  return Swal.fire({
    title: `Delete listing: ${name}?`,
    text: "You cannot undo this action",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Confirm",
    buttonsStyling: false,
  });
}

// Fires after successful creating a listing
export function createListingSuccessAlert() {
  return Swal.fire({
    title: `Listing Created`,
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

// Fires after successful listing delete
export function deleteListingSuccessAlert() {
  return Swal.fire({
    title: `Listing Deleted`,
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

// Fires after successful listing delete
export function reservationDeleteAlert() {
  return Swal.fire({
    title: `Reservation Cancelled`,
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

export function successChangeAlert() {
  return Swal.fire({
    title: `Dates Changed!`,
    icon: "success",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "button-terciary big-btn",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

// Cant delete a listing if its already reserved
export function cantDeleteListingAlert() {
  return Swal.fire({
    title: `Cannot Delete Listing`,
    text: "This listing is already reserved, it cannot be deleted",
    icon: "error",
    customClass: {
      confirmButton: "button-terciary big-btn",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

export function badDateInput() {
  return Swal.fire({
    title: `Bad Date Input`,
    text: "The end date must be after the start date.",
    icon: "error",
    customClass: {
      confirmButton: "button-terciary big-btn",
    },
    confirmButtonText: "Okay",
    buttonsStyling: false,
  });
}

// Thanks the user after they leave a rating
export function thankYou() {
  Swal.fire({
    title: `Thank you!`,
    text: "Ratings like yours help the Jackrabbit Markplace remain a safe and trusted community.",
    customClass: {
      confirmButton: "button-primary big-btn mr15",
    },
    confirmButtonText: "Okay!",
    buttonsStyling: false,
  });
}

// Stripe return link popups
export function returnFromStripeAlert(thenFunction) {
  return Swal.fire({
    title: `Congratulations!`,
    text: "You have become a Maker. If you haven't yet, please make sure to complete all necessary Stripe onboarding to enable payouts.",
    icon: "success",
    showCancelButton: false,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
      cancelButton: "order-2",
    },
    confirmButtonText: "Confirm",
    cancelButtonText: "CANCEL",
    buttonsStyling: false,
  }).then(() => {
    thenFunction();
  });
}

export function reauthFromStripeAlert(thenFunction) {
  return Swal.fire({
    title: `Something went wrong during Stripe onboarding`,
    text: "You have successfully become a Maker, but it looks like your Stripe session or link expired. Please try to complete Stripe onboarding again to enable payouts",
    icon: "warning",
    showCancelButton: false,
    customClass: {
      confirmButton: "button-primary big-btn mr15",
    },
    confirmButtonText: "Confirm",
    buttonsStyling: false,
  }).then(() => {
    thenFunction();
  });
}

// export function makeAdminModal(thenFunction) {
//   return Swal.fire({
//     title: `Are you sure you want to make this user an Admin?`,
//     icon: "warning",
//     showCancelButton: false,
//     customClass: {
//       confirmButton: "button-primary big-btn mr15",
//     },
//     confirmButtonText: "Confirm",
//     buttonsStyling: false,
//   }).then(() => {
//     thenFunction();
//   });
// }

// export function confirmAdmin() {

// }

export async function showConfirmationDialog(message) {
  try {
    const result = await Swal.fire({
      title: message,
      icon: "warning",
      showCancelButton: false,
      customClass: {
        confirmButton: "button-primary big-btn mr15",
      },
      confirmButtonText: "Confirm",
      buttonsStyling: false,
    });
    return result.isConfirmed;
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error displaying confirmation dialog:", error);
    return false;
  }
}

// export function confirmationRevokeDialog(message) {
//   return Swal.fire({
//     title: message,
//     icon: "warning",
//     showCancelButton: false,
// customClass: {
//   confirmButton: "button-primary big-btn mr15",
// },
// confirmButtonText: "Confirm",
// buttonsStyling: false,
//   }).then(() => {
//     thenFunction();
//   });
// }

// MISC Functions

// Partition function
// Splits an array in 2 based on filter
export function partition(array, filter) {
  const pass = [];
  const fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
}

// Gets the text before a comma,
// Used to extract street address from full location
export function getTextBeforeComma(str) {
  const regex = /^([^,]+)/;
  const match = str.match(regex);
  if (match) {
    return match[1];
  }
  return "";
}
// Has been 7 days
// Checks if a date is 7 days old
// returns true is so

export function hasBeenSevenDays(dateString) {
  // Convert the provided date string to a Date object
  const providedDate = new Date(dateString);

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the difference in milliseconds between the current date and the provided date
  const timeDiff = currentDate.getTime() - providedDate.getTime();

  // Calculate the number of milliseconds in 7 days
  const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;

  // Check if the time difference is greater than or equal to 7 days
  return timeDiff >= sevenDaysInMillis;
}

// Takes DateTime
// Returns the month, and year as text
// Used in ProfileHeader and ProfileReviews

export function formatDateMonthYear(datetime) {
  const options = { year: "numeric", month: "long" };
  const date = new Date(datetime);

  const monthYear = date.toLocaleDateString(undefined, options);
  const [month, year] = monthYear.split(" ");

  return `${month}, ${year}`;
}

export function dateToString(date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateAsObj = new Date(date);
  const dayName = days[dateAsObj.getUTCDay()];
  const monthName = months[dateAsObj.getUTCMonth()];
  const day = dateAsObj.getUTCDate();

  return `${dayName}, ${monthName} ${day}`;
}

export function formatTimestamp(timestamp) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const time = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${month} ${day}, ${time}`;
}
