import React, { useState } from "react";
import Userfront from "@userfront/core";
import { FaEye, FaEyeSlash } from "react-icons/fa";

Userfront.init("9nywgrgn");

function PasswordResetPage() {
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const togglePasswordAgainVisibility = () =>
    setShowPasswordAgain(!showPasswordAgain);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    if (password !== passwordAgain) {
      setMessage("Passwords do not match.");
      setIsSubmitting(false);
      return;
    }

    try {
      await Userfront.resetPassword({ password });
      setMessage("Password reset successful! Redirecting to login...");
      setTimeout(() => {
        window.location.href = "https://www.jackrabt.com/login";
      }, 2000);
    } catch (error) {
      setMessage("Error resetting password. Please try again.");
      /* eslint-disable no-console */
      console.error("Password Reset Error:", error);
    }

    setIsSubmitting(false);
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "auto",
        textAlign: "center",
        marginTop: "100px",
      }}
    >
      <h2>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        {/* Password Field */}
        <div style={{ position: "relative", marginBottom: "10px" }}>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              paddingRight: "40px",
            }}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "0",
              top: "50%",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: "5px",
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        {/* Confirm Password Field */}
        <div style={{ position: "relative", marginBottom: "10px" }}>
          <input
            type={showPasswordAgain ? "text" : "password"}
            placeholder="Confirm new password"
            value={passwordAgain}
            onChange={(e) => setPasswordAgain(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              paddingRight: "40px",
            }}
          />
          <button
            type="button"
            onClick={togglePasswordAgainVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: "5px",
            }}
          >
            {showPasswordAgain ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#0275d8",
            color: "white",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {isSubmitting ? "Processing..." : "Reset Password"}
        </button>
      </form>
      {message && (
        <p
          style={{
            marginTop: "10px",
            color: message.includes("successful") ? "green" : "red",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
}

export default PasswordResetPage;
